/* JavaScript for ShareActions */

// Export the class itself
export class SearchActions {
  constructor(element) {
    this.element = element;
    this.searchInput = element.querySelector(".js-search-input");
    this.searchButton = element.querySelector(".js-tray-search__submit");
    this.state = {
      term: "",
    };

    this.searchInput.addEventListener("input", () => {
      const enabledClass = "js-tray-search__submit--enabled";
      const submitEnabled = this.searchButton.classList.contains(enabledClass);
      this.updateSearchTerm();
      if (this.state.term.trim() !== "" && !submitEnabled) {
        this.searchButton.classList.add(enabledClass);
        this.searchButton.removeAttribute("disabled");
      } else if (this.state.term.trim() === "" && submitEnabled) {
        this.searchButton.classList.remove(enabledClass);
        this.searchButton.setAttribute("disabled", "disabled");
      }
    });

    this.searchInput.addEventListener("keyup", (e) => {
      if (e.keyCode === 13) {
        this.updateSearchTerm();
      }
    });
  }

  updateSearchTerm() {
    this.state.term = this.searchInput.value;
  }
}
